<template>
  <page-layout>
    <span slot="header-name">{{ $tf('common.events') }}</span>
    <div slot="content">
      <el-form
        v-loading="loading"
        :size="$vars.sizes.form"
        :label-position="$vars.forms.label_position"
        :labelWidth="$vars.forms.label_width"
        :name="$options.name"
      >
        <el-form-item :label="$tf('common.detected') + ' / ' + $tf('common.matched_to')">
          <div class="cols flex-multi">
            <object-thumbnail :frame="event.thumbnail" :url="event.fullframe" @preview="() => showEventImage(event)"></object-thumbnail>
            <object-thumbnail
              :id="event.matched_object"
              :objects="objectsType"
              :requiredId="!!event.matched_dossier"
              @preview="(v) => showSourceImage(v && v.src)"
            ></object-thumbnail>
          </div>
        </el-form-item>
        <el-form-item :label="$tf('video')" v-if="hasVideo">
          <div v-loading="video.loading" :class="video.loading || video.error ? 'video-content' : 'video-player'">
            <video ref="videoPlayer" controls muted :poster="video.playerOptions.poster" class="video-player" v-if="video.playerOptions.src"></video>
            <div v-else>
              {{ video.error ? $tf('video_stream | not_found') : '' }}
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="$tf('common.id')" v-if="event.id">
          <span name="id">{{ event.id }}</span>
          <a v-if="eventsLinks('events')" name="search-events-btn" :href="getSearchPath({ id: event.id, target: 'events' })" target="_blank">
            <i class="mar-left--1 el-icon-search"></i> {{ $tf('common.events') }}
          </a>
          <a v-if="eventsLinks('dossiers')" name="search-dossiers-btn" :href="getSearchPath({ id: event.id, target: 'dossiers' })" target="_blank">
            <i class="mar-left--1 el-icon-search"></i> {{ $tf('common.dossiers') }}
          </a>
          <a
            v-if="$store.getters.features.social_search && eventsLinks('social')"
            name="search-social-btn"
            :href="getSearchPath({ id: event.id, target: 'social' })"
            target="_blank"
          >
            <i class="mar-left--1 el-icon-search"></i> {{ $tf('common.social_nets') }}
          </a>
        </el-form-item>
        <el-form-item :label="$tf('common.name')">
          <span v-if="dossier.id">
            <a name="to-dossier-btn" :href="'#/dossiers/' + dossier.id + '/'" target="_blank">{{ dossier.name }} ({{ dossier.id }})</a>
            <a name="search-dossier-btn" :href="getFilterPath({ matched_dossier: dossier.id })" target="_blank"><i class="mar-left--1 el-icon-search"></i></a>

            <a name="upload-event-face-dossier-btn" @click="addEventObjectToDossier" v-loading="objectUploading" class="link" v-if="!objectAddedToDossier">
              <i class="mar-left--1 el-icon-plus"></i> {{ $tfo('add_object_to_dossier') }}
            </a>
            <span class="mar-left--1" v-else>
              {{ $tf('object_was_added') }}
            </span>
          </span>
          <span v-else class="text-gray">
            {{ $t('common.no') }}
            <a name="create-dossier-btn" :href="'#/dossiers/create/?eventId=' + event.id" target="_blank">
              <i class="mar-left--1 el-icon-plus"></i> {{ $tfo('create | dossier,,1') }}
            </a>
          </span>
        </el-form-item>
        <el-form-item :label="$tf('video')" v-if="event.video_archive">
          <a name="video_url" :href="videoArchiveUrl" target="_blank">
            {{ event.video_archive }}
          </a>
        </el-form-item>
        <el-form-item :label="$tf('common.confidence')">
          <div name="confidence" v-if="dossier.id">
            <confidence-label v-if="hasFacesConfidence" :value="event.confidence" :objects="this.objectsType"></confidence-label>
            <span v-else>{{ event.confidence | floatToPercent }}</span>
          </div>
          <div v-else class="text-gray">{{ $t('common.no_matches') }}</div>
        </el-form-item>
        <el-form-item :label="$tf('common.episode')" v-if="event.episode">
          <a name="episode" :href="getFilterPath({ episode: event.episode })" target="_blank">
            <i class="mar-left--1 el-icon-search"></i> {{ event.episode }}
          </a>
        </el-form-item>

        <el-form-item :label="$tf('common.comment')">
          <span name="comment" v-if="dossier.id && dossier.comment">{{ dossier.comment }}</span>
          <span v-else class="text-gray">{{ $t('common.no') }}</span>
        </el-form-item>
        <el-form-item v-if="$store.getters.features.temperature" :label="$tf('temperature')">
          <span name="temperature">{{ event.temperature ? event.temperature : '-' }}</span>
        </el-form-item>
        <el-form-item :label="$tf('features')" v-if="event.features">
          <features :features="event.features" :objects-type="objectsType" />
        </el-form-item>
        <el-form-item :label="$tf('common.datetime')" v-if="event.created_date">
          <span name="created-date">{{ event.created_date | parseDate | formatDateTime }}</span>
        </el-form-item>
        <el-form-item v-if="camera.id" :label="$tf('common.camera')">
          <a name="search-camera-btn" :href="getFilterPath({ cameras: [camera.id] })" target="_blank">
            <i class="mar-left--1 el-icon-search"></i> {{ camera.name }}
          </a>
        </el-form-item>
        <el-form-item v-if="cameraGroup.name" :label="$tf('common.camera_group')">
          <a name="search-camera-group-btn" :href="getFilterPath({ camera_groups: camera.group ? [camera.group] : [cameraGroup.id] })" target="_blank"
            ><i class="mar-left--1 el-icon-search"></i> {{ cameraGroup.name }}</a
          >
        </el-form-item>
        <el-form-item :label="$tf('common.dossier_lists')">
          <dossier-lists-inline links :objects-type="objectsType" :ids="event.matched_lists" />
        </el-form-item>
        <el-form-item :label="$tf('acknowledged_datetime')" v-if="event.acknowledged">
          <span name="acknowledged-date">{{ event.acknowledged_date | parseDate | formatDateTime }}</span>
        </el-form-item>
        <el-form-item :label="$tf('acknowledged_by')" v-if="event.acknowledged_by">
          <span name="acknowledged-by">{{ getUserName(event.acknowledged_by) || 'user.id:' + event.acknowledged_by }}</span>
        </el-form-item>
        <el-form-item :label="$tf('common.status')">
          <event-reaction :item="event" :objects="this.objectsType"></event-reaction>
        </el-form-item>
        <el-form-item>
          <el-button name="back-btn" :plain="true" @click="cancelHandler">{{ $tf('common.back') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </page-layout>
</template>

<script>
import _ from '@/apps/common/lodash';
import qs from 'qs';
import ObjectThumbnail from '../objects/thumbnail.vue';
import DossierListsInline from '../dossier-lists/inline.vue';
import EventReaction from './reaction.vue';
import Hls from 'hls.js';
import ConfidenceLabel from '../common/confidence.label';
import Features from '../common/features.vue';
import { ObjectsTypeSingleForm } from '@/store/objects/get.module';
import HeaderBackButton from '@/components/common/header.back.button';
import PageLayout from '@/components/page/layout';
import { ImageType } from '../../store/actions/showImageByType';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

export default {
  components: {
    PageLayout,
    Features,
    ConfidenceLabel,
    EventReaction,
    DossierListsInline,
    ObjectThumbnail,
    HeaderBackButton
  },
  name: 'event-form',
  data: function () {
    return {
      objectAddedToDossier: false,
      video: {
        loading: true,
        error: false,
        playerOptions: {
          src: '',
          poster: ''
        }
      },
      dossier: {
        id: null,
        name: '',
        comment: '',
        dossier_lists: []
      },
      event: _.cloneDeep(this.$store.state.faces_events.item.empty),
      user: null,
      camera: {
        id: null,
        name: '',
        group: {
          id: null,
          name: ''
        }
      },
      cameraGroup: {
        id: null,
        name: ''
      },
      loading: false,
      objectUploading: false
    };
  },
  computed: {
    hasFacesConfidence() {
      return this.$store.getters.hasFacesConfidence;
    },
    hasVideo() {
      return this.$store.state.config.dossier && this.$store.state.config.dossier.video;
    },
    objectsType() {
      const { objects } = this.$route.params;
      const objectsType = objects ?? 'faces';
      return objectsType;
    },
    stateName() {
      return `${this.objectsType}_events`;
    },
    state() {
      return this.$store.state[this.stateName];
    },
    videoArchiveUrl() {
      return `#/videos/${this.event.video_archive}`;
    }
  },
  mounted() {
    let id = this.$route.params.id;
    this.load(id);
  },
  beforeDestroy() {
    this.$hls && this.$hls.destroy();
  },
  methods: {
    getUserName(id) {
      let user = [this.user, this.$store.state.users.current].find((v) => v && v.id === id);
      return user ? user.name + (user.real_name ? ` (${user.real_name})` : '') : '';
    },
    showEventImage(item) {
      this.$store.dispatch('showImageByType', { type: ImageType.Event, objectsType: this.objectsType, data: item });
    },
    showSourceImage(source) {
      this.$store.dispatch('showImageByType', { type: ImageType.Source, objectsType: this.objectsType, data: source });
    },
    loadUser(id) {
      this.$store.dispatch(this.$store.state.users.Action.Get, { id }).then((v) => {
        this.user = v;
      });
    },
    getVideo(id) {
      const type = this.$filters.firstCase(this.objectsType);
      this.video.loading = true;
      this.video.error = false;

      this.$store
        .dispatch(`get${type}EventVideoUrl`, { id })
        .then((v) => {
          this.video.loading = false;
          if (!(v && v.url)) return Promise.reject('Server response is malformed. Url not found.');
          else {
            this.video.playerOptions = { poster: this.event.frame, src: v.url };
            this.$nextTick(() => this.setupVideoPlayer());
          }
        })
        .catch((e) => {
          this.video.loading = false;
          this.video.error = true;
        });
    },
    setupVideoPlayer() {
      if (Hls.isSupported()) {
        this.$hls = new Hls({ maxMaxBufferLength: 60 });
        this.$hls.loadSource(this.video.playerOptions.src);
        this.$hls.attachMedia(this.$refs.videoPlayer);
      } else {
        this.$refs.videoPlayer.src = this.video.playerOptions.src;
      }
    },
    async load(id) {
      try {
        this.loading = true;
        this.event = await this.fetchEventById(id);
        this.hasVideo && this.getVideo(id);
        if (this.event.matched_dossier) {
          this.dossier = await this.fetchDossierById(this.event.matched_dossier);
        }
        this.camera = await this.fetchCameraById(this.event.camera);
        this.cameraGroup = await this.fetchCameraGroupById(this.event.camera_group);
        const userId = this.event.acknowledged_by;
        userId && this.loadUser(userId);
      } catch (e) {
        this.$notify({
          duration: 0,
          message: this.$createElement('message-box', { props: { e } })
        });
      } finally {
        this.loading = false;
      }
    },
    fetchEventById(id) {
      return this.dispatchGetByIdAction(this.state.Action.Get, id);
    },
    async fetchDossierById(id) {
      try {
        return await this.dispatchGetByIdAction(this.$store.state.dossiers.Action.Get, id);
      } catch (e) {
        return { id, name: this.$tf('error') };
      }
    },
    fetchCameraById(id) {
      return this.dispatchGetByIdAction(this.$store.state.cameras.Action.Get, id);
    },
    fetchCameraGroupById(id) {
      return this.dispatchGetByIdAction(this.$store.state.camera_groups.Action.Get, id);
    },
    dispatchGetByIdAction(actionName, id) {
      return this.$store.dispatch(actionName, { id });
    },
    getFilterPath(filter) {
      return `#/events/${this.objectsType}/filter/` + qs.stringify(Object.assign(filter, { limit: 20 }), { sort: alphabeticalSort });
    },
    getSearchPath({ id, target }) {
      const objectsSingleForm = ObjectsTypeSingleForm[this.objectsType];
      return this.$store.getters.getObjectsSearchPath({ objects: this.objectsType, looks_like: `${objectsSingleForm}event:${id}`, target });
    },
    cancelHandler(e) {
      this.$router.back();
    },
    saveHandler(e) {
      let instance = _.pick(this.event, ['acknowledged', 'id']);
      this.$store
        .dispatch(this.state.Action.Update, instance)
        .then((v) => {
          this.user = this.$store.state.users.current;
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    addEventObjectToDossier() {
      this.objectUploading = true;
      this.$store
        .dispatch('uploadObject', {
          create_from: `${ObjectsTypeSingleForm[this.objectsType]}event:${this.event.id}`,
          dossier: this.dossier.id,
          objects: this.objectsType
        })
        .then((v) => {
          this.objectAddedToDossier = true;
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        })
        .finally(() => {
          this.objectUploading = false;
        });
    },
    eventsLinks(name) {
      const { objects } = this.$route.params;
      const types = {
        faces: ['events', 'dossiers', 'social'],
        cars: ['events', 'dossiers'],
        bodies: ['events', 'dossiers']
      };
      return types[objects].indexOf(name) !== -1;
    }
  }
};
</script>

<style>
.video-player {
  background-color: #000;
  width: 640px;
  height: 360px;
}

.video-content {
  width: 320px;
  height: 40px;
}

.text-gray {
  color: #ccc;
}
</style>
